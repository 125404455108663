import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import store from './common/store/store'
/**
 * Copyright 2022, Bradley P Twelves, All rights reserved.
 */
 import { createTheme, CssBaseline } from '@mui/material';
 
 import "./index.css"
 //import red from "@mui/material/colors/red";
 //import orange from "@mui/material/colors/orange";
 //import yellow from "@mui/material/colors/yellow";
 //import green from "@mui/material/colors/green";
 //import lightBlue from "@mui/material/colors/lightBlue";
 //import grey from "@mui/material/colors/grey"; 
import { ThemeProvider } from '@mui/system';



 const myTheme = createTheme({
  typography: {
    fontFamily: '"Playfair Display", serif'
  },
  palette: {    
    mode: 'light',
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#eeeeee',
      paper: '#ffffff',      
    },
  },
  
});





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Provider store={store}>
    <Router>      
    <ThemeProvider theme={myTheme}>      
    <CssBaseline />
      <App />
      </ThemeProvider>
    </Router>
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
