import initialState from './state';
import { DEAUTHENTICATED, 
  AUTHENTICATE_BEGIN, 
  AUTHENTICATE_FAILURE, 
  AUTHENTICATE_SUCCESS, 
  REAUTHENTICATE, 
  REGISTER_REQUEST, 
  REGISTER_SUCCESS, 
  REGISTER_FAILURE } from './actions';

export default function reducer(state = initialState, action) {
    
    switch (action.type){
        case DEAUTHENTICATED:
            return {...initialState};            
        case AUTHENTICATE_BEGIN:
            console.log('DEBUG', 'authenticate', 'begin', action);
            return {
                ...initialState,                
                isAuthenticating: true                
            };
        case AUTHENTICATE_FAILURE:
            console.log('DEBUG', 'authenticate', 'failure', action);
            return {
                ...state, 
                isAuthenticating: false,                                                                
            };
        case AUTHENTICATE_SUCCESS:
          console.log('DEBUG', 'authenticate', 'success', action.payload);
            return {
                ...state, 
                isAuthenticating: false, 
                authenticated: true,
                data: {...action.payload},
                user: action.payload.user,
                role: action.payload.role,
                token: {
                    bearer: action.payload.token,
                    expiresAt: new Date(action.payload.expires)
                }                
            };
        case REAUTHENTICATE:
            console.log('DEBUG', 'reauthenticate', 'begin', action);
            return {...action.data};            

        case REGISTER_REQUEST:
          console.log('DEBUG', 'authenticate', 'register request');
          break;

        case REGISTER_SUCCESS:
          console.log('DEBUG', 'authenticate', 'register success');
          break
           
        case REGISTER_FAILURE:
          console.log('DEBUG', 'authenticate', 'register failure');          
          break
          

        default:
            return state;

    }
}