 /**
 * Copyright 2022, Bradley P Twelves, All rights reserved.
 */
import { compose, createStore, applyMiddleware } from 'redux';
//import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer from './reducers';
import mwFactory from '../middleware';

 
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
 
const store = createStore(
    rootReducer, 
    composeEnhancers( 
        applyMiddleware(
            ...mwFactory
        )));
  
// add the store to the global variables list as a convenience in our development environments
if (process.env.NODE_ENV === 'development') {
    window.store = store;
}
  
export default store;
 
 
	
	
