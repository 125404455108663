import React from 'react';

import { AppBar, Box, Paper, Grid, Typography } from '@mui/material';

import Stack from '@mui/material/Stack';

import Toolbar from '@mui/material/Toolbar';

import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()}
      {' '}
      <Link color="inherit" href="https://aarcadian.com/">
        Aarcadian, LLC
      </Link>{' '}
      - All Rights Reserved

      {'.'}
    </Typography>
  );
}

const Home = (props) => {

  return (
    <div className="App">
      <AppBar position="relative">
        <Toolbar>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}

        <Container maxWidth="med">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Aarcadian Containers
          </Typography>
          <Typography variant="h5" align="center" color="text.secondary" paragraph>
            WHOLESALE SHIPPING CONTAINERS
          </Typography>
        </Container>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          
        >
          <Box
            justifyContent="center"
            alignItems="center"
            component="img"
            alt=""
            src={require('../static/aarcadian.jpg')}
          />
        </Grid>
        <Stack
          sx={{ pt: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          

        </Stack>
      </main>
      {/* Footer */}
      <Box  sx={{ bottom: '0', bgcolor: 'background.paper', p: 6 }} component="footer">
      <Typography variant="h5" align="center" color="text.secondary" paragraph>
            Container Trading, Import & Wholesale
          </Typography>
        
      </Box>
      {/* End footer */}
      <Paper sx={{marginTop: 'calc(10% + 60px)',
    width: '100%',
    position: 'fixed',
    bottom: 0,    
    }} component="footer" square variant="outlined">
      <Container maxWidth="lg">
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            my:1
          }}
        >
            <div>
            
            </div>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            mb: 2,
          }}
        >          
          <Copyright />                          
        </Box>
      </Container>
    </Paper>
  

    </div>
  );
}

export default Home