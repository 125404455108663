const initialState  = {
    isAuthenticating: false,
    authenticated:  false,
    authenticatedAt: new Date(0),

    user: null,
    role: '',
    token: {
        bearer: '',
        expiresAt: new Date(0)
    },
};

export default initialState;