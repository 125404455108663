 /**
 * Copyright 2022, Bradley P Twelves, All rights reserved.
 */
import { combineReducers } from 'redux';
import authReducer from './auth/reducer'
import alertReducer from './alert/alert.reducer'
 
const rootReducer = combineReducers({
auth: authReducer,
alert: alertReducer, 
});

export default rootReducer;