 /**
 * Copyright 2022, Bradley P Twelves, All rights reserved.
 */
import React, { useEffect } from 'react';
import { useDispatch} from 'react-redux';
import { userReauthenticate } from './common/store/auth/actions';
//import UnauthenticatedApp from './components/unauthenticatedApp';
//import Inventory from './pages/inventory';
import Home from './pages/home';

//import './App.css'



const App = () => {  

  const dispatch = useDispatch();
  //const auth = useSelector(state => state.auth);

  //const isAuthenticated = auth.user;

  useEffect(() => {
    dispatch(userReauthenticate());  
  }, [dispatch])
  
  //const inventory = <Inventory/>
  //const unauthentictedApp = <UnauthenticatedApp/>

  return (    
    <div>
      <Home/>
      
    {/*isAuthenticated ?
      inventory
    :
      unauthentictedApp
  */}
    
    </div>

  );
}

export default App;
