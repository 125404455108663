 /**
 * Copyright 2022, Bradley P Twelves, All rights reserved.
 */
import thunk from 'redux-thunk';
//import { createLogger } from 'redux-logger';

//const logger = createLogger();

const mwFactory = [];
 
// thunk is mandatory
mwFactory.push(thunk);
 
// logger writes each action to the console
//if (process.env.REACT_APP_REDUX_LOGGER === 'true') {
//  mwFactory.push(logger);
//}

 
export default mwFactory;
 